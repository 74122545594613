import { Button } from "../../../land_ui/button/button";
import { formatCurrency } from "../utils";

interface CompPopupProperties {
    title: string;
    properties: any;
}

export default function CompPopup({ title, properties }: CompPopupProperties) {
    return (
        <div>
            <h5>{title}</h5>
            <ul>
                <li>
                    <strong>Lot size</strong>: {properties.lot_size} acres
                </li>
                <li>
                    <strong>Price</strong>: {formatCurrency(properties.price)}
                </li>
                <li>
                    <strong>PPA</strong>: {formatCurrency(properties.ppa)}
                </li>
                <li>
                    <strong>Status</strong>: {properties.status}
                </li>
                <li>
                    <strong>Subdivision</strong>: {properties.subdivision || "N/A"}
                </li>
                <li>
                    <strong>Days on market</strong>: {properties.days_on_market}
                </li>
            </ul>
            <Button href={properties.url} openInNewTab={true}>
                View parcel
            </Button>
        </div>
    );
}
