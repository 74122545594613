import { useState } from "react";
import { Button } from "../../../land_ui/button/button";
import { Icon } from "../../../land_ui/icon/icon";
import { Sidebar } from "../../../land_ui/sidebar/sidebar";
import { Typography } from "../../../land_ui/typography/typography";
import { useExportsList, useSavedlistsList } from "../../../orval/gen/api";
import { ExportListMenu } from "./export_list_menu";
import { ExportsMenuItem } from "./export_menu";
import { FilterMenuItem } from "./filter_list_menu";
import { MenuSidebarProps, useMenuSidebar } from "./useMenuSidebar";

interface MapMenuProps {
    onFilterClick: () => void;
}

export function MapMenu({ onFilterClick }: MapMenuProps) {
    const [state, dispatch] = useMenuSidebar();
    const [enabledApi, setEnabledApi] = useState(false);
    const { data: exportsData, isLoading: exportListLoading } = useExportsList(
        {},
        { query: { enabled: enabledApi, staleTime: 0, queryKey: ["exportList"] } },
    );
    const { data: filterSavedList, isLoading: isFilterListLoading } = useSavedlistsList(
        {},
        { query: { enabled: enabledApi, staleTime: 0, queryKey: ["savedFilterList"] } },
    );

    return (
        <Sidebar
            trigger={
                <Button
                    variant="secondary"
                    icon="Hamburger"
                    onClick={() => {
                        setEnabledApi(true);
                    }}
                />
            }
            onClose={() => {}}
        >
            {state.currentView === "MAIN" && (
                <MainMenuView
                    state={state}
                    dispatch={dispatch}
                    filterCount={filterSavedList?.count || 0}
                    exportCount={exportsData?.count || 0}
                    loading={exportListLoading || isFilterListLoading}
                />
            )}

            {state.currentView === "FILTER" && (
                <FilterMenuItem
                    state={state}
                    dispatch={dispatch}
                    savedListsResult={filterSavedList}
                    onFilterClick={onFilterClick}
                />
            )}
            {state.currentView === "EXPORT" && (
                <ExportsMenuItem
                    state={state}
                    dispatch={dispatch}
                    exportListResult={exportsData}
                    filterCount={exportsData?.count || 0}
                />
            )}

            {state.currentView === "EXPORT_PARCELS_LIST" && (
                <ExportListMenu
                    exportId={state.selectedExport.id}
                    goBack={() => {
                        dispatch({ type: "SET_VIEW", view: "EXPORT" });
                    }}
                />
            )}
        </Sidebar>
    );
}

interface MainMenuViewProps extends MenuSidebarProps {
    filterCount?: number;
    exportCount?: number;
    loading?: boolean;
}
function MainMenuView({
    dispatch,
    filterCount,
    exportCount,
    loading,
}: MainMenuViewProps) {
    return (
        <>
            <Sidebar.Header>Menu</Sidebar.Header>
            <Sidebar.Content>
                <MenuItem
                    title="Saved Filters"
                    count={filterCount}
                    loading={loading}
                    onClick={() => {
                        dispatch({ type: "SET_VIEW", view: "FILTER" });
                    }}
                />

                <MenuItem
                    title="Exports"
                    count={exportCount}
                    loading={loading}
                    onClick={() => {
                        dispatch({ type: "SET_VIEW", view: "EXPORT" });
                    }}
                />
            </Sidebar.Content>
        </>
    );
}

interface MenuItemProps {
    onClick?: () => void;
    count?: number;
    title: string;
    loading?: boolean;
}

function MenuItem({ onClick, count, loading, title }: MenuItemProps) {
    return (
        <Button variant="base" fullWidth onClick={onClick} disabled={count === 0}>
            <span className="lui-flex lui-justify-between lui-items-center lui-p-6 hover:lui-bg-gray-50 lui-border-b lui-border-t-0 lui-border-x-0 lui-border-solid lui-border-gray-100">
                <span className="lui-flex lui-items-center lui-gap-1">
                    <Typography size="md" weight="medium">
                        {title}
                    </Typography>
                    <Typography size="sm" color="gray-700">
                        {loading ? "Loading..." : ` (${count})`}
                    </Typography>
                </span>
                <Icon name="RightArrow" />
            </span>
        </Button>
    );
}
